import { Environment } from "react-relay"
import {
  commitLocalUpdate,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import {
  BigNumber,
  bn,
  conditionalPriceRounding,
} from "@/lib/helpers/numberUtils"

type Props = {
  address: string
  collectionRelayId: string
  pricePerUnit: BigNumber
  quantity: BigNumber
  selectedTrait: SelectedTrait
}

export const commitNewCollectionOfferInTranche = (
  environment: Environment,
  { pricePerUnit, quantity, address, collectionRelayId, selectedTrait }: Props,
) => {
  commitLocalUpdate(environment, store => {
    const collectionProxy = store.get(collectionRelayId)
    if (!collectionProxy) {
      return
    }

    const identity = { address }
    const traitConfig = selectedTrait
      ? {
          name: selectedTrait.key,
          value: selectedTrait.value,
        }
      : null

    const collectionTranches = collectionProxy.getLinkedRecords(
      "collectionTranches",
      traitConfig ? { identity, traitConfig } : { identity },
    ) as (RecordProxy<object> | null | undefined)[] | null | undefined

    if (!collectionTranches) {
      return
    }

    const offerPrices: string[] = collectionTranches
      .map(collectionTranch =>
        String(collectionTranch?.getLinkedRecord("price")?.getValue("unit")),
      )
      .map(p => conditionalPriceRounding(p).toString())

    const roundedPriceOfNewOffer =
      conditionalPriceRounding(pricePerUnit).toString()
    const trancheIndex = offerPrices.indexOf(roundedPriceOfNewOffer)
    const totalPrice = pricePerUnit.times(quantity)

    if (trancheIndex == -1) {
      handleNewTrancheInsertion({
        collectionProxy,
        identity,
        collectionTranches,
        store,
        quantity,
        pricePerUnit,
        volume: totalPrice,
        traitConfig,
      })
      return
    }

    const trancheToUpdate = collectionTranches[trancheIndex]
    if (!trancheToUpdate) {
      return
    }
    handleExistingTrancheUpdate({
      trancheToUpdate,
      quantity,
      totalPrice,
    })
  })
}

type NewTrancheProps = {
  collectionProxy: RecordProxy<object>
  collectionTranches: (RecordProxy<object> | null | undefined)[]
  pricePerUnit: BigNumber
  quantity: BigNumber
  volume: BigNumber
  identity: { address: string }
  store: RecordSourceProxy
  traitConfig: { name: string; value: string } | null
}

const handleNewTrancheInsertion = ({
  collectionProxy,
  collectionTranches,
  identity,
  store,
  quantity,
  pricePerUnit,
  volume,
  traitConfig,
}: NewTrancheProps) => {
  const collectionTranchesDataID = `collectionTranches(identity:${JSON.stringify(
    identity,
  )}${traitConfig ? `,traitConfig:${JSON.stringify(traitConfig)}` : ""})`
  const newTrancheDataID = `client:${collectionProxy.getDataID()}:${collectionTranchesDataID}:${
    collectionTranches.length
  }`
  const getAccountDataID = `getAccount(address:"${identity.address}")`

  const newTranche =
    store.get(newTrancheDataID) ??
    store.create(newTrancheDataID, "CollectionTrancheType")
  newTranche.setValue(false, "isFloor")
  newTranche.setValue(quantity.toString(), "yourOffers")
  newTranche.setValue(quantity.toString(), "totalOffers")
  newTranche.setValue("1", "totalBidders")

  const price = newTranche.getOrCreateLinkedRecord("price", "PriceType")
  price.setValue("WETH", "symbol")
  price.setValue(pricePerUnit.toString(), "unit")

  const totalVolume = newTranche.getOrCreateLinkedRecord(
    "totalVolume",
    "PriceType",
  )
  totalVolume.setValue("WETH", "symbol")
  totalVolume.setValue(volume.toString(), "unit")

  const account = store.getRoot().getLinkedRecord(getAccountDataID)
  newTranche.setLinkedRecords(account ? [account] : [], "topBidders")

  const newTranches = [...collectionTranches, newTranche]
    .sort((a, b) => {
      const aPrice = a?.getLinkedRecord("price")?.getValue("unit")
      const bPrice = b?.getLinkedRecord("price")?.getValue("unit")
      return Number(bPrice) - Number(aPrice)
    })
    .filter(tranche => tranche !== undefined) as (RecordProxy<object> | null)[]

  collectionProxy.setLinkedRecords(newTranches, collectionTranchesDataID)
}

type ExistingTrancheProps = {
  trancheToUpdate: RecordProxy<object>
  quantity: BigNumber
  totalPrice: BigNumber
}

const handleExistingTrancheUpdate = ({
  trancheToUpdate,
  quantity,
  totalPrice,
}: ExistingTrancheProps) => {
  const totalOffersForThisTranche = trancheToUpdate.getValue("totalOffers")
  const yourOffersForThisTranche = trancheToUpdate.getValue("yourOffers")
  const totalBiddersForThisTranche = trancheToUpdate.getValue("totalBidders")
  const totalVolumeForThisTranche =
    trancheToUpdate.getLinkedRecord("totalVolume")

  if (totalOffersForThisTranche !== undefined) {
    trancheToUpdate.setValue(
      quantity.plus(Number(totalOffersForThisTranche)).toString(),
      "totalOffers",
    )
  }

  if (Number(yourOffersForThisTranche) === 0) {
    // Set new totalBidders only if it's the first time
    // this user has bid for this tranche
    trancheToUpdate.setValue(
      bn(Number(totalBiddersForThisTranche ?? 0))
        .plus(1)
        .toString(),
      "totalBidders",
    )
  }

  if (yourOffersForThisTranche !== undefined) {
    trancheToUpdate.setValue(
      quantity.plus(Number(yourOffersForThisTranche)).toString(),
      "yourOffers",
    )
  }

  if (totalVolumeForThisTranche) {
    const unit = totalVolumeForThisTranche.getValue("unit")
    if (unit !== undefined) {
      totalVolumeForThisTranche.setValue(
        totalPrice.plus(Number(unit)).toString(),
        "unit",
      )
    }
  }
}
