/**
 * @generated SignedSource<<4a9b0cad7e544bdf00171aaea996ae5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SweepContextProvider_items$data = ReadonlyArray<{
  readonly orderData: {
    readonly bestAskV2: {
      readonly payment: {
        readonly symbol: string;
      };
      readonly perUnitPriceType: {
        readonly unit: string;
      };
      readonly relayId: string;
      readonly " $fragmentSpreads": FragmentRefs<"BulkPurchaseModal_orders" | "useTotalPrice_orders">;
    } | null;
  };
  readonly relayId: string;
  readonly " $fragmentType": "SweepContextProvider_items";
}>;
export type SweepContextProvider_items$key = ReadonlyArray<{
  readonly " $data"?: SweepContextProvider_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"SweepContextProvider_items">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SweepContextProvider_items",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "payment",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPriceType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BulkPurchaseModal_orders"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useTotalPrice_orders"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "7d3f3b87c377a38c9d6e2d8d1c2f8920";

export default node;
