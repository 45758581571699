import React from "react"
import { Skeleton } from "@opensea/ui-kit"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { Button } from "@/design-system/Button"
import { useCollectionBestOffer } from "@/features/orders/hooks/useCollectionBestOffer"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  collectionSlug: string
  selectedTrait: SelectedTrait
  onClick: (offer: string, bestOfferSymbol?: string) => unknown
}

export const SetToBestOfferButton = ({
  collectionSlug,
  selectedTrait,
  onClick,
}: Props) => {
  const t = useTranslate("orders")
  const { unit: bestOffer, symbol: bestOfferSymbol } = useCollectionBestOffer({
    collectionSlug,
    selectedTrait,
  })

  if (!bestOffer) {
    return null
  }

  return (
    <Button
      className="mt-3 w-full min-w-[183px] sm:ml-3 sm:mt-0 sm:w-[183px]"
      variant="secondary"
      onClick={() => onClick(bestOffer, bestOfferSymbol)}
    >
      {t("offerModal.setToBestOfferButton", "Set to best offer")}
    </Button>
  )
}

export const SetToBestOfferButtonSkeleton = () => {
  return (
    <Skeleton.Block className="mt-3 h-12 w-full rounded-xl sm:ml-3 sm:mt-0 sm:w-[183px]" />
  )
}
