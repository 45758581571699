import { getTrackingFn } from "@/lib/analytics"

export const trackToggleSweepMode = getTrackingFn<{ toggled: boolean }>(
  "toggle sweep mode",
)

export const trackClickSweepButton = getTrackingFn<{
  numItems: number
  substituteItems: boolean
}>("click sweep button")

export const trackSubstituteItemsChecked = getTrackingFn<{ checked: boolean }>(
  "check substitute items",
)
