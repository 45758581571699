import React from "react"

type Props = {
  className?: string
  height?: number
  width?: number
  color?: string
}

export const BroomIcon = ({
  color,
  className,
  width = 24,
  height = 24,
}: Props) => (
  <svg
    className={className}
    color={color}
    height={height}
    version="1.1"
    viewBox="0 0 682.66669 682.66669"
    width={width}
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    y="0"
  >
    <g>
      <defs id="defs718" strokeWidth="40">
        <clipPath
          clipPathUnits="userSpaceOnUse"
          id="clipPath728"
          strokeWidth="40"
        >
          <path
            d="M 0,512 H 512 V 0 H 0 Z"
            fill="currentColor"
            id="path726"
            strokeWidth="40"
          />
        </clipPath>
      </defs>
      <g
        id="g720"
        strokeWidth="40"
        transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)"
      >
        <g id="g722" strokeWidth="40">
          <g clipPath="url(#clipPath728)" id="g724" strokeWidth="40">
            <g
              id="g730"
              strokeWidth="40"
              transform="translate(180.0488,14.7358)"
            >
              <path
                d="M 0,0 -165.049,165.049 -5.657,278.186 107.48,165.049 Z"
                fill="none"
                id="path732"
                stroke="currentColor"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity=""
                strokeWidth="40"
              />
            </g>
            <g
              id="g734"
              strokeWidth="40"
              transform="translate(174.3921,292.9219)"
            >
              <path
                d="m 0,0 c 31.242,31.242 81.895,31.242 113.137,0 31.242,-31.242 31.242,-81.895 0,-113.137 z"
                fill="none"
                id="path736"
                stroke="currentColor"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity=""
                strokeWidth="40"
              />
            </g>
            <g id="g738" strokeWidth="40" transform="translate(497,497)">
              <path
                d="M 0,0 -206.84,-206.84"
                fill="none"
                id="path740"
                stroke="currentColor"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity=""
                strokeWidth="40"
              />
            </g>
            <g id="g742" strokeWidth="40" transform="translate(184,136)">
              <path
                d="M 0,0 -49.69,-74.53"
                fill="none"
                id="path744"
                stroke="currentColor"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity=""
                strokeWidth="40"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
