import React, { useCallback, useMemo, useState } from "react"
import BigNumber from "bignumber.js"
import { graphql, useFragment } from "react-relay"
import { createContext, useContextSelector } from "use-context-selector"
import { useDebouncedCallback } from "use-debounce"
import { SweepContextProvider_collection$key } from "@/lib/graphql/__generated__/SweepContextProvider_collection.graphql"
import {
  SweepContextProvider_items$data,
  SweepContextProvider_items$key,
} from "@/lib/graphql/__generated__/SweepContextProvider_items.graphql"
import { MAX_SWEEP_ITEMS } from "./constants"

type ItemSweepStatus = {
  isEligible: boolean
  isMostProbableToSweep: boolean
}

type SweepItemsContextType = {
  eligibleItems: SweepContextProvider_items$data
  setEligibleItemsKey: (items: SweepContextProvider_items$key) => void
  itemIneligibilityReasonMap: Map<string, string>
  setItemIneligibilityReasonMap: (
    itemIneligibilityReasonMap: Map<string, string>,
  ) => void
  orders: NonNullable<
    SweepContextProvider_items$data[number]["orderData"]["bestAskV2"]
  >[]
  getItemSweepStatus: (itemRelayId?: string) => ItemSweepStatus
  sweepUpToItem: (itemRelayId?: string) => void
  isPreviewHighlighted: (itemRelayId?: string) => boolean
}

const SweepItemsContext = createContext<SweepItemsContextType>({
  eligibleItems: [],
  setEligibleItemsKey: () => null,
  itemIneligibilityReasonMap: new Map(),
  setItemIneligibilityReasonMap: () => null,
  orders: [],
  getItemSweepStatus: () => ({
    isEligible: false,
    isMostProbableToSweep: false,
  }),
  sweepUpToItem: () => null,
  isPreviewHighlighted: () => false,
})

type SweepFormContextType = {
  desiredNumberOfItems?: number
  setDesiredNumberOfItems: (desiredNumberOfItems?: number) => void
  maxPricePerItem?: BigNumber
  setMaxPricePerItem: (maxPricePerItem?: BigNumber) => void
  paymentAsset?: Partial<
    NonNullable<
      SweepContextProvider_items$data[number]["orderData"]["bestAskV2"]
    >["payment"]
  >
  isSweepModeToggled?: boolean
  setResetSweepForm: (reset: () => void) => void
  resetSweepForm: (() => void) | undefined
}

export const DEFAULT_SWEEP_FORM_CONTEXT = {
  desiredNumberOfItems: undefined,
  setDesiredNumberOfItems: () => null,
  maxPricePerItem: undefined,
  setMaxPricePerItem: () => null,
  paymentAsset: undefined,
  setResetSweepForm: () => null,
  resetSweepForm: undefined,
}

export const SweepFormContext = createContext<SweepFormContextType>(
  DEFAULT_SWEEP_FORM_CONTEXT,
)

type SweepItemHoverContextType = {
  hoveredItemRelayId?: string
  setHoveredItemRelayId: (itemRelay?: string) => void
}

const SweepItemHoverContext = createContext<SweepItemHoverContextType>({
  hoveredItemRelayId: undefined,
  setHoveredItemRelayId: () => null,
})

type Props = {
  children: React.ReactNode
  collection: SweepContextProvider_collection$key | null
}

export const SweepContextProvider = ({
  children,
  collection: collectionDataKey,
}: Props) => {
  const [eligibleItemKeys, setEligibleItemsKey] =
    useState<SweepContextProvider_items$key>([])
  const [hoveredItemRelayId, setHoveredItemRelayId] = useState<
    string | undefined
  >()

  // Debounce the function to optimise for multiple quick state changes
  const setHoveredItemRelayIdDebounced = useDebouncedCallback(
    (itemRelayId?: string) => {
      setHoveredItemRelayId(itemRelayId)
    },
    30,
  )
  const [itemIneligibilityReasonMap, setItemIneligibilityReasonMap] = useState<
    Map<string, string>
  >(new Map())

  const eligibleItems = useFragment(
    graphql`
      fragment SweepContextProvider_items on ItemType @relay(plural: true) {
        relayId
        orderData {
          bestAskV2 {
            relayId
            payment {
              symbol
            }
            # eslint-disable-next-line relay/unused-fields
            perUnitPriceType {
              unit
            }
            # eslint-disable-next-line relay/must-colocate-fragment-spreads
            ...BulkPurchaseModal_orders
            # eslint-disable-next-line relay/must-colocate-fragment-spreads
            ...useTotalPrice_orders
          }
        }
      }
    `,
    eligibleItemKeys,
  )

  const orders = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => eligibleItems.flatMap(item => item.orderData?.bestAskV2 ?? []),
    [eligibleItems],
  )

  const [desiredNumberOfItems, setDesiredNumberOfItems] = useState<
    number | undefined
  >(undefined)

  const [maxPricePerItem, setMaxPricePerItem] = useState<BigNumber | undefined>(
    undefined,
  )

  const [resetSweepForm, dispatchSetResetSweepForm] = useState<
    (() => void) | undefined
  >(undefined)

  const collection = useFragment(
    graphql`
      fragment SweepContextProvider_collection on CollectionType {
        nativePaymentAsset {
          symbol
        }
      }
    `,
    collectionDataKey,
  )

  const payment = eligibleItems.length
    ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      eligibleItems[0].orderData?.bestAskV2?.payment
    : collection?.nativePaymentAsset
  const paymentAsset = useMemo(
    () => ({
      symbol: payment?.symbol,
    }),
    [payment],
  )

  // If the item is not in the list of items, it's not eligible for sweep.
  // Otherwise, it's most probably to sweep if the index of the item is less than the desired number of items.
  const getItemSweepStatus = useCallback(
    (itemRelayId?: string) => {
      const itemIndex = eligibleItems.findIndex(
        ({ relayId }) => relayId === itemRelayId,
      )

      const isEligible = itemIndex !== -1

      return {
        isEligible,
        isMostProbableToSweep:
          isEligible && itemIndex < (desiredNumberOfItems ?? 0),
      }
    },
    [eligibleItems, desiredNumberOfItems],
  )

  const sweepUpToItem = useCallback(
    (itemRelayId?: string) => {
      const itemIndex = eligibleItems.findIndex(
        ({ relayId }) => relayId === itemRelayId,
      )

      if (itemIndex >= 0 && itemIndex < MAX_SWEEP_ITEMS) {
        setDesiredNumberOfItems(itemIndex + 1)
      }
    },
    [eligibleItems],
  )

  const isPreviewHighlighted = useCallback(
    (itemRelayId?: string) => {
      const itemIndex = eligibleItems.findIndex(i => i.relayId === itemRelayId)
      return (
        !!hoveredItemRelayId &&
        getItemSweepStatus(itemRelayId).isEligible &&
        itemIndex <=
          eligibleItems.findIndex(i => i.relayId === hoveredItemRelayId) &&
        itemIndex <= MAX_SWEEP_ITEMS
      )
    },
    [eligibleItems, getItemSweepStatus, hoveredItemRelayId],
  )

  const sweepItemsValue = useMemo(
    () => ({
      eligibleItems,
      setEligibleItemsKey,
      itemIneligibilityReasonMap,
      setItemIneligibilityReasonMap,
      orders,
      getItemSweepStatus,
      sweepUpToItem,
      isPreviewHighlighted,
    }),
    [
      eligibleItems,
      getItemSweepStatus,
      isPreviewHighlighted,
      itemIneligibilityReasonMap,
      orders,
      sweepUpToItem,
    ],
  )

  const setResetSweepForm = useCallback(
    (reset: () => void) => dispatchSetResetSweepForm(_prev => reset),
    [],
  )

  const sweepFormValue = useMemo(
    () => ({
      desiredNumberOfItems,
      setDesiredNumberOfItems,
      maxPricePerItem,
      setMaxPricePerItem,
      paymentAsset,
      isSweepModeToggled: desiredNumberOfItems !== undefined,
      setResetSweepForm,
      resetSweepForm,
    }),
    [
      desiredNumberOfItems,
      maxPricePerItem,
      paymentAsset,
      resetSweepForm,
      setResetSweepForm,
    ],
  )

  const sweepHoverValue = useMemo(
    () => ({
      hoveredItemRelayId,
      setHoveredItemRelayId: setHoveredItemRelayIdDebounced,
    }),
    [hoveredItemRelayId, setHoveredItemRelayIdDebounced],
  )

  return (
    <SweepItemsContext.Provider value={sweepItemsValue}>
      <SweepFormContext.Provider value={sweepFormValue}>
        <SweepItemHoverContext.Provider value={sweepHoverValue}>
          {children}
        </SweepItemHoverContext.Provider>
      </SweepFormContext.Provider>
    </SweepItemsContext.Provider>
  )
}

export const useSweepEligibleItems = () => ({
  eligibleItems: useContextSelector(
    SweepItemsContext,
    state => state.eligibleItems,
  ),
  setEligibleItemsKey: useContextSelector(
    SweepItemsContext,
    state => state.setEligibleItemsKey,
  ),
})

export const useSweepItemIneligibilityReasonMap = () => ({
  itemIneligibilityReasonMap: useContextSelector(
    SweepItemsContext,
    state => state.itemIneligibilityReasonMap,
  ),

  setItemIneligibilityReasonMap: useContextSelector(
    SweepItemsContext,
    state => state.setItemIneligibilityReasonMap,
  ),
})

export const useSweepOrders = () =>
  useContextSelector(SweepItemsContext, state => state.orders)

export const useGetItemSweepStatus = () =>
  useContextSelector(SweepItemsContext, state => state.getItemSweepStatus)

export const useSweepUpToItem = () =>
  useContextSelector(SweepItemsContext, state => state.sweepUpToItem)

export const useIsSweepPreviewHighlighted = () =>
  useContextSelector(SweepItemsContext, state => state.isPreviewHighlighted)

export const useSweepFormDesiredNumberOfItems = () => ({
  desiredNumberOfItems: useContextSelector(
    SweepFormContext,
    state => state.desiredNumberOfItems,
  ),
  setDesiredNumberOfItems: useContextSelector(
    SweepFormContext,
    state => state.setDesiredNumberOfItems,
  ),
})

export const useSweepFormMaxPricePerItem = () => ({
  maxPricePerItem: useContextSelector(
    SweepFormContext,
    state => state.maxPricePerItem,
  ),
  setMaxPricePerItem: useContextSelector(
    SweepFormContext,
    state => state.setMaxPricePerItem,
  ),
})

export const useSweepFormPaymentAsset = () =>
  useContextSelector(SweepFormContext, state => state.paymentAsset)

export const useSweepFormIsSweepModeToggled = () =>
  useContextSelector(SweepFormContext, state => state.isSweepModeToggled)

export const useSetResetSweepForm = () =>
  useContextSelector(SweepFormContext, state => state.setResetSweepForm)

export const useResetSweepForm = () =>
  useContextSelector(SweepFormContext, state => state.resetSweepForm)

export const useSweepHoveredItemRelayId = () => ({
  hoveredItemRelayId: useContextSelector(
    SweepItemHoverContext,
    state => state.hoveredItemRelayId,
  ),
  setHoveredItemRelayId: useContextSelector(
    SweepItemHoverContext,
    state => state.setHoveredItemRelayId,
  ),
})
