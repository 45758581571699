/**
 * @generated SignedSource<<317d4ab0012e699ca257bfaa8feec7c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionFooter_collection$data = {
  readonly defaultChain: {
    readonly isTradingEnabled: boolean;
  };
  readonly isCollectionOffersEnabled: boolean;
  readonly isTraitOffersEnabled: boolean;
  readonly slug: string;
  readonly " $fragmentType": "CollectionFooter_collection";
};
export type CollectionFooter_collection$key = {
  readonly " $data"?: CollectionFooter_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionFooter_collection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionFooter_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCollectionOffersEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTraitOffersEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "defaultChain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTradingEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "8514e0732470e3227562dc9ede58adaa";

export default node;
