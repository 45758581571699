import { Search } from "@/hooks/useSearch"

type Props = {
  stringTraits: Search["stringTraits"]
}

export const getSelectedTraitFromSearchStateStringTraits = ({
  stringTraits,
}: Props) => {
  const stringTraitName =
    stringTraits && stringTraits.length ? stringTraits[0].name : undefined
  const stringTraitValue =
    stringTraits && stringTraitName && stringTraits[0].values.length
      ? stringTraits[0].values[0]
      : undefined

  return stringTraitName && stringTraitValue
    ? {
        key: stringTraitName,
        value: stringTraitValue,
        floor: null,
        count: 1,
      }
    : null
}
