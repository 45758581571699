import BigNumber from "bignumber.js"
import { useLazyLoadQuery, graphql } from "react-relay"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import {
  TraitInputType,
  useCollectionBestOfferQuery,
} from "@/lib/graphql/__generated__/useCollectionBestOfferQuery.graphql"
import { getFirstNode } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"

type Props = {
  collectionSlug: string
  selectedTrait: SelectedTrait
}

export const useCollectionBestOffer = ({
  collectionSlug,
  selectedTrait,
}: Props) => {
  const trait: TraitInputType | undefined =
    selectedTrait?.key && selectedTrait.value
      ? {
          name: selectedTrait.key,
          values: [selectedTrait.value],
        }
      : undefined

  const { bestCollectionOffers, bestTraitOffers } =
    useLazyLoadQuery<useCollectionBestOfferQuery>(
      graphql`
        query useCollectionBestOfferQuery(
          $collectionSlug: CollectionSlug!
          $trait: TraitInputType
          $hasTrait: Boolean!
        ) {
          bestCollectionOffers: collection(collection: $collectionSlug) {
            collectionOffers(first: 1) {
              edges {
                node {
                  perUnitPriceType {
                    unit
                    usd
                    symbol
                  }
                }
              }
            }
          }
          bestTraitOffers: collection(collection: $collectionSlug)
            @include(if: $hasTrait) {
            collectionOffers(first: 1, traitCriteria: $trait) {
              edges {
                node {
                  perUnitPriceType {
                    unit
                    usd
                    symbol
                  }
                }
              }
            }
          }
        }
      `,
      { collectionSlug, trait, hasTrait: Boolean(trait) },
    )

  const bestCollectionOffer = getFirstNode(
    bestCollectionOffers?.collectionOffers,
  )

  const bestTraitOffer = getFirstNode(bestTraitOffers?.collectionOffers)

  const maxUsd = BigNumber.maximum(
    bestCollectionOffer?.perUnitPriceType.usd ?? 0,
    bestTraitOffer?.perUnitPriceType.usd ?? 0,
  )

  const bestOffer = [bestCollectionOffer, bestTraitOffer].find(offer =>
    bn(maxUsd).eq(offer?.perUnitPriceType.usd ?? 0),
  )

  return {
    unit: bestOffer?.perUnitPriceType.unit,
    symbol: bestOffer?.perUnitPriceType.symbol,
  }
}
