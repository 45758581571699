/**
 * @generated SignedSource<<1e3d6c67e009f261ac2fbed06d2f6256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionOfferModal_tradeLimits$data = {
  readonly minimumBidUsdPrice: string;
  readonly " $fragmentSpreads": FragmentRefs<"useOfferModalAdapter_tradeLimits">;
  readonly " $fragmentType": "CollectionOfferModal_tradeLimits";
};
export type CollectionOfferModal_tradeLimits$key = {
  readonly " $data"?: CollectionOfferModal_tradeLimits$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionOfferModal_tradeLimits">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionOfferModal_tradeLimits",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumBidUsdPrice",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOfferModalAdapter_tradeLimits"
    }
  ],
  "type": "TradeLimitsType",
  "abstractKey": null
};

(node as any).hash = "19a2c0d056dc507cc775e564e917cb90";

export default node;
