/**
 * @generated SignedSource<<1dd86c99c9af3c9ac9eb99fa00769395>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TraitInputType = {
  name: string;
  values: ReadonlyArray<string>;
};
export type useCollectionBestOfferQuery$variables = {
  collectionSlug: string;
  hasTrait: boolean;
  trait?: TraitInputType | null;
};
export type useCollectionBestOfferQuery$data = {
  readonly bestCollectionOffers: {
    readonly collectionOffers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly perUnitPriceType: {
            readonly symbol: string;
            readonly unit: string;
            readonly usd: string;
          };
        } | null;
      } | null>;
    };
  } | null;
  readonly bestTraitOffers?: {
    readonly collectionOffers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly perUnitPriceType: {
            readonly symbol: string;
            readonly unit: string;
            readonly usd: string;
          };
        } | null;
      } | null>;
    };
  } | null;
};
export type useCollectionBestOfferQuery = {
  response: useCollectionBestOfferQuery$data;
  variables: useCollectionBestOfferQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTrait"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "trait"
},
v3 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collectionSlug"
  }
],
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceType",
  "kind": "LinkedField",
  "name": "perUnitPriceType",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrderV2TypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderV2Type",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "traitCriteria",
    "variableName": "trait"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrderV2TypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderV2Type",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCollectionBestOfferQuery",
    "selections": [
      {
        "alias": "bestCollectionOffers",
        "args": (v3/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "OrderV2TypeConnection",
            "kind": "LinkedField",
            "name": "collectionOffers",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "collectionOffers(first:1)"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasTrait",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "bestTraitOffers",
            "args": (v3/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrderV2TypeConnection",
                "kind": "LinkedField",
                "name": "collectionOffers",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCollectionBestOfferQuery",
    "selections": [
      {
        "alias": "bestCollectionOffers",
        "args": (v3/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "OrderV2TypeConnection",
            "kind": "LinkedField",
            "name": "collectionOffers",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": "collectionOffers(first:1)"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasTrait",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "bestTraitOffers",
            "args": (v3/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrderV2TypeConnection",
                "kind": "LinkedField",
                "name": "collectionOffers",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b094a4e31ea879e47625ab2c70386ab5",
    "id": null,
    "metadata": {},
    "name": "useCollectionBestOfferQuery",
    "operationKind": "query",
    "text": "query useCollectionBestOfferQuery(\n  $collectionSlug: CollectionSlug!\n  $trait: TraitInputType\n  $hasTrait: Boolean!\n) {\n  bestCollectionOffers: collection(collection: $collectionSlug) {\n    collectionOffers(first: 1) {\n      edges {\n        node {\n          perUnitPriceType {\n            unit\n            usd\n            symbol\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n  bestTraitOffers: collection(collection: $collectionSlug) @include(if: $hasTrait) {\n    collectionOffers(first: 1, traitCriteria: $trait) {\n      edges {\n        node {\n          perUnitPriceType {\n            unit\n            usd\n            symbol\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "299d34d6a65b1d88a70835077b0c1f3a";

export default node;
