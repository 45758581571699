import React from "react"
import { Text, Skeleton } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { PriceText } from "@/design-system/PriceText"
import { useCollectionBestOffer } from "@/features/orders/hooks/useCollectionBestOffer"
import { useTranslate } from "@/hooks/useTranslate"
import { OfferPriceDetails_collection$key } from "@/lib/graphql/__generated__/OfferPriceDetails_collection.graphql"
import { OfferPriceDetails_floorPrice$key } from "@/lib/graphql/__generated__/OfferPriceDetails_floorPrice.graphql"

type Props = {
  collection: OfferPriceDetails_collection$key | null
  selectedTrait: SelectedTrait
}

export const OfferPriceDetails = ({
  collection: collectionDataKey,
  selectedTrait,
}: Props) => {
  const t = useTranslate("orders")
  const collection = useFragment(
    graphql`
      fragment OfferPriceDetails_collection on CollectionType {
        ...OfferPriceDetails_floorPrice
        slug
      }
    `,
    collectionDataKey,
  )

  const { unit: bestOfferUnit, symbol: bestOfferSymbol } =
    useCollectionBestOffer({
      collectionSlug: collection?.slug ?? "",
      selectedTrait,
    })

  const { unit: floorPriceUnit, symbol: floorPriceSymbol } =
    useTraitOrCollectionFloorPrice({
      collection,
      selectedTrait,
    }) ?? {}

  if (!collection) {
    return null
  }

  return (
    <>
      {floorPriceUnit && floorPriceSymbol && (
        <StyledText $withDivider={Boolean(bestOfferUnit)} size="small">
          {`${t("offerModal.floorPrice", "Floor price")}: `}
          <PriceText
            color="inherit"
            size="small"
            symbol={floorPriceSymbol}
            unit={floorPriceUnit}
          />
        </StyledText>
      )}
      {bestOfferUnit && bestOfferSymbol && (
        <StyledText size="small">
          {`${t("offerModal.bestOffer", "Best offer")}: `}
          <PriceText
            color="inherit"
            size="small"
            symbol={bestOfferSymbol}
            unit={bestOfferUnit}
          />
        </StyledText>
      )}
    </>
  )
}

type TraitOrCollectionFloorPriceProps = {
  collection: OfferPriceDetails_floorPrice$key | null
  selectedTrait: SelectedTrait
}

const useTraitOrCollectionFloorPrice = ({
  collection: collectionKey,
  selectedTrait,
}: TraitOrCollectionFloorPriceProps) => {
  const collection = useFragment(
    graphql`
      fragment OfferPriceDetails_floorPrice on CollectionType {
        stringTraits(withTraitFloor: true) {
          key
          counts {
            value
            floor {
              unit
              symbol
            }
          }
        }
        statsV2 {
          floorPrice {
            unit
            symbol
          }
        }
      }
    `,
    collectionKey,
  )

  if (!selectedTrait) {
    return collection?.statsV2.floorPrice
  }

  if (selectedTrait.floor?.unit && selectedTrait.floor.symbol) {
    return selectedTrait.floor
  }

  // NOTE(@laurafiuza): Sometimes we set selectedTrait without
  // knowing floor price information. In other words, `floor`
  // object is null. Thus, we fetched this data in the fragment above.
  const fetchedKey = collection?.stringTraits.find(
    trait => trait.key === selectedTrait.key,
  )
  if (!fetchedKey) {
    return collection?.statsV2.floorPrice
  }

  // TODO(@laurafiuza): Grant already has a PR for a resolver that queries
  // based on a single trait instead of having to filter through all
  // string traits. Fast-following to unblock launch.
  const fetchedValue = fetchedKey.counts.find(
    count => count.value === selectedTrait.value,
  )
  if (!fetchedValue?.floor?.unit || !fetchedValue.floor.symbol) {
    return collection?.statsV2.floorPrice
  }

  return { unit: fetchedValue.floor.unit, symbol: fetchedValue.floor.symbol }
}

export const OfferPriceDetailsSkeleton = () => {
  return <Skeleton.Line className="h-[22px] w-[200px]" />
}

const StyledText = styled(Text.Body)<{ $withDivider?: boolean }>`
  ${props =>
    props.$withDivider &&
    css`
      border-right: 1px solid ${props.theme.colors.components.border.level2};
      padding-right: 8px;
      margin-right: 8px;
    `}
  color: ${props => props.theme.colors.text.secondary};
`
