import React from "react"
import { breakpoints, Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { Button, ButtonWithIconProps } from "@/design-system/Button"
import { CollectionOfferMultiModal } from "@/features/orders/components/CollectionOfferMultiModal"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionOfferProvider } from "../CollectionOfferProvider"
import { trackClickMakeCollectionOffer } from "../OfferModal/analytics"

type MakeCollectionOfferButtonProps = {
  slug: string
  title?: React.ReactNode
  overrides?: {
    Button?: Partial<ButtonWithIconProps>
  }
  selectedTrait?: SelectedTrait
}

export const MakeCollectionOfferButton = ({
  slug,
  title,
  overrides = { Button: {} },
  selectedTrait,
}: MakeCollectionOfferButtonProps) => {
  const { handleUnconnectedWallet } = useWalletModal()

  const t = useTranslate("orders")
  return (
    <SsrSuspense fallback={null}>
      <CollectionOfferProvider>
        <CollectionOfferMultiModal
          selectedTrait={selectedTrait}
          slug={slug}
          trigger={open => {
            const onClick = () => {
              trackClickMakeCollectionOffer()
              handleUnconnectedWallet(open)
            }
            return (
              <StyledButton
                variant="secondary"
                onClick={onClick}
                {...overrides.Button}
              >
                {title ?? (
                  <Text.Body
                    className="whitespace-nowrap text-inherit"
                    size="medium"
                    weight="semibold"
                  >
                    {selectedTrait
                      ? t("traitOffer.title", "Make trait offer")
                      : t("collectionOffer.title", "Make collection offer")}
                  </Text.Body>
                )}
              </StyledButton>
            )
          }}
        />
      </CollectionOfferProvider>
    </SsrSuspense>
  )
}

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    width: auto;
  }
`
