/**
 * @generated SignedSource<<75fa3a596724a73ddf657179c0f5463a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionOfferModal_collectionData$data = {
  readonly isTraitOffersEnabled: boolean;
  readonly name: string;
  readonly relayId: string;
  readonly slug: string;
  readonly statsV2: {
    readonly floorPrice: {
      readonly symbol: string;
      readonly usd: string;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CollectionOfferDetails_collection" | "OfferPriceDetails_collection" | "useOfferModalAdapter_collection">;
  readonly " $fragmentType": "CollectionOfferModal_collectionData";
};
export type CollectionOfferModal_collectionData$key = {
  readonly " $data"?: CollectionOfferModal_collectionData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionOfferModal_collectionData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionOfferModal_collectionData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTraitOffersEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "floorPrice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferPriceDetails_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOfferModalAdapter_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionOfferDetails_collection"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "f041cb8ba0a5b3bfcc941a8f7d92b93c";

export default node;
