import React, { Suspense, useCallback, useEffect } from "react"
import { useLazyLoadQuery } from "react-relay"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { UncontrolledModalProps } from "@/design-system/Modal"
import { useCollectionOfferContext } from "@/features/orders/hooks/useCollectionOfferContext"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { CollectionOfferMultiModalBaseQuery } from "@/lib/graphql/__generated__/CollectionOfferMultiModalBaseQuery.graphql"
import { CollectionOfferMultiModalQuery } from "@/lib/graphql/__generated__/CollectionOfferMultiModalQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import { CollectionOfferProvider } from "../CollectionOfferProvider"
import { CollectionOfferProviderProps } from "../CollectionOfferProvider/CollectionOfferProvider.react"
import { CollectionOfferModal } from "../OfferModal/CollectionOfferModal.react"

export type CollectionOfferMultiModalProps = {
  slug: string
  trigger: UncontrolledModalProps["trigger"]
  selectedTrait?: SelectedTrait
}

export const CollectionOfferMultiModal = ({
  slug,
  trigger,
  selectedTrait,
}: CollectionOfferMultiModalProps) => {
  const collectionOfferContext = useCollectionOfferContext()
  const { setSelectedTrait } = collectionOfferContext
  const { openModal, closeModal } = useGlobalModal()

  useEffect(() => {
    if (selectedTrait !== undefined) {
      setSelectedTrait(selectedTrait)
    }
  }, [selectedTrait, setSelectedTrait])

  const { collection } = useLazyLoadQuery<CollectionOfferMultiModalQuery>(
    graphql`
      query CollectionOfferMultiModalQuery($collection: CollectionSlug!) {
        collection(collection: $collection) {
          defaultChain {
            identifier
          }
        }
      }
    `,
    { collection: slug },
  )

  const onClose = () => {
    closeModal()
    if (selectedTrait !== undefined) {
      setSelectedTrait(selectedTrait)
    }
  }

  const openCollectionOfferModal = () =>
    openModal(
      // Hack to ensure OfferModal has access to CollectionOfferContext
      // Needed since this is a global modal mounted by GlobalModalProvider
      <Suspense fallback={<ModalLoader />}>
        <CollectionOfferMultiModalBase
          chain={collection?.defaultChain.identifier}
          defaultOfferPrice={collectionOfferContext.defaultOfferPrice}
          defaultSelectedTrait={collectionOfferContext.selectedTrait}
          slug={slug}
          onClose={closeModal}
        />
      </Suspense>,
      {
        onClose,
        focusFirstFocusableElement: false,
        size: "medium",
      },
    )

  return trigger(openCollectionOfferModal)
}

const CollectionOfferMultiModalBase = ({
  slug,
  onClose,
  chain,
  defaultOfferPrice,
  defaultSelectedTrait,
}: Pick<CollectionOfferMultiModalProps, "slug"> &
  Omit<CollectionOfferProviderProps, "children"> & {
    chain?: ChainIdentifier
    onClose: () => unknown
  }) => {
  const { wallet } = useWallet()

  const {
    collection,
    tradeLimits,
    getAccount: account,
  } = useLazyLoadQuery<CollectionOfferMultiModalBaseQuery>(
    graphql`
      query CollectionOfferMultiModalBaseQuery(
        $address: AddressScalar!
        $collection: CollectionSlug!
        $chain: ChainScalar!
      ) {
        collection(collection: $collection) {
          slug
          ...CollectionOfferModal_collectionData
        }
        tradeLimits(chain: $chain) {
          ...CollectionOfferModal_tradeLimits
        }
        getAccount(address: $address) {
          ...CollectionOfferModal_account
        }
      }
    `,
    {
      collection: slug,
      chain: chain ?? getEthereumChain(),
      address: wallet.getActiveAddressStrict(),
    },
  )

  const renderOfferModal = useCallback(() => {
    return (
      <CollectionOfferProvider
        defaultOfferPrice={defaultOfferPrice}
        defaultSelectedTrait={defaultSelectedTrait}
      >
        <CollectionOfferModal
          account={account}
          collection={collection}
          tradeLimits={tradeLimits}
          onClose={onClose}
        />
      </CollectionOfferProvider>
    )
  }, [
    defaultOfferPrice,
    defaultSelectedTrait,
    account,
    collection,
    tradeLimits,
    onClose,
  ])

  return renderOfferModal()
}
