/**
 * @generated SignedSource<<51e6c2c1e08745c817b54597609230c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferPriceDetails_floorPrice$data = {
  readonly statsV2: {
    readonly floorPrice: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
  };
  readonly stringTraits: ReadonlyArray<{
    readonly counts: ReadonlyArray<{
      readonly floor: {
        readonly symbol: string;
        readonly unit: string;
      } | null;
      readonly value: string;
    }>;
    readonly key: string;
  }>;
  readonly " $fragmentType": "OfferPriceDetails_floorPrice";
};
export type OfferPriceDetails_floorPrice$key = {
  readonly " $data"?: OfferPriceDetails_floorPrice$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferPriceDetails_floorPrice">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferPriceDetails_floorPrice",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "withTraitFloor",
          "value": true
        }
      ],
      "concreteType": "StringTraitType",
      "kind": "LinkedField",
      "name": "stringTraits",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StringTraitCountType",
          "kind": "LinkedField",
          "name": "counts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "floor",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "stringTraits(withTraitFloor:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "floorPrice",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "2796f02704e2a2d731b45455de4c8cf0";

export default node;
