import React, { useEffect, useMemo, useRef } from "react"
import {
  Media,
  useIsLessThanLg,
  useIsLessThanXl,
  Text,
  Switch,
  Label,
  classNames,
  SpaceBetween,
  FlexColumn,
  Input,
  Checkbox,
  Slider,
} from "@opensea/ui-kit"
import { isEmpty, keyBy, mapValues, sortBy } from "lodash"
import { Controller } from "react-hook-form"
import { useUpdateEffect } from "react-use"
import styled, { css } from "styled-components"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { BroomIcon } from "@/components/svgs/BroomIcon.react"
import { ButtonWithPriceLabelContainer } from "@/components/trade/AcceptHighestOfferButton"
import { BulkPurchaseModal } from "@/components/trade/BulkPurchaseModal"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { BottomSheet } from "@/design-system/BottomSheet"
import { Button } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import { Tooltip } from "@/design-system/Tooltip"
import { useTotalPrice } from "@/features/shopping-cart/hooks/useTotalPrice"
import {
  MAX_BACKUP_ORDERS_TO_FILL,
  MAX_SWEEP_ITEMS,
  DEFAULT_NUM_SWEEP_ITEMS,
  MAX_PRICE_PER_ITEM_BUFFER,
} from "@/features/sweep/constants"
import {
  useSweepOrders,
  useSweepEligibleItems,
  useSweepFormDesiredNumberOfItems,
  useSweepFormPaymentAsset,
  useSweepFormMaxPricePerItem,
  useSetResetSweepForm,
} from "@/features/sweep/SweepContextProvider.react"
import {
  trackSubstituteItemsChecked,
  trackToggleSweepMode,
  trackClickSweepButton,
} from "@/features/sweep/utils/analytics"
import { useForm } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import { bn, display } from "@/lib/helpers/numberUtils"

const itemQuantity = () => 1

type SweepFormData = {
  sweepModeToggled: boolean
  numItems: number
  substituteItemsChecked: boolean
  maxPricePerItem: string
}

const sliderClassName = classNames("w-full rounded-r-none border-r-0")

export const SweepForm = () => {
  const orders = useSweepOrders()
  const { eligibleItems } = useSweepEligibleItems()
  // Used to prevent cycles between useEffects of numItems and contextNumItems
  // TODO: refactor this bit entirely to use stores.
  const latestSetContextDesiredNumberOfItems = useRef<number | undefined>()

  const {
    desiredNumberOfItems: contextDesiredNumberOfItems,
    setDesiredNumberOfItems: setContextDesiredNumberOfItems,
  } = useSweepFormDesiredNumberOfItems()

  const paymentAsset = useSweepFormPaymentAsset()

  const { setMaxPricePerItem: setContextMaxPricePerItem } =
    useSweepFormMaxPricePerItem()
  const setResetSweepForm = useSetResetSweepForm()

  const isLessThanLg = useIsLessThanLg()
  const isLessThanXl = useIsLessThanXl()
  const isBetweenLgAndXl = isLessThanXl && !isLessThanLg

  const t = useTranslate("bulkPurchase")

  const numEligibleItems = eligibleItems.length

  const defaultValues = useMemo(
    () => ({
      sweepModeToggled: false,
      numItems: DEFAULT_NUM_SWEEP_ITEMS,
      substituteItemsChecked: false,
      maxPricePerItem: "",
    }),
    [],
  )

  const {
    register,
    control,
    watch,
    trigger,
    formState,
    setFocus,
    reset,
    setValue,
  } = useForm<SweepFormData>({
    mode: "onChange",
    defaultValues,
  })

  useEffect(() => {
    setResetSweepForm(() => reset(defaultValues))
  }, [defaultValues, reset, setResetSweepForm])

  const [sweepModeToggled, numItems, substituteItemsChecked, maxPricePerItem] =
    watch([
      "sweepModeToggled",
      "numItems",
      "substituteItemsChecked",
      "maxPricePerItem",
    ])

  const includedOrders = substituteItemsChecked
    ? orders.slice(0, numItems + MAX_BACKUP_ORDERS_TO_FILL)
    : orders.slice(0, numItems)

  const orderToQuantity = useMemo(
    () => mapValues(keyBy(includedOrders, "relayId"), itemQuantity),
    [includedOrders],
  )

  const totalPrice = useTotalPrice({
    orders,
    orderToQuantity,
    maxOrdersToFill: Number(numItems),
  })

  useUpdateEffect(() => {
    if (sweepModeToggled) {
      setContextDesiredNumberOfItems(Number(numItems))
    } else {
      setContextDesiredNumberOfItems(undefined)
    }
  }, [sweepModeToggled])

  useUpdateEffect(() => {
    if (substituteItemsChecked) {
      if (maxPricePerItem) {
        setContextMaxPricePerItem(bn(maxPricePerItem))
      } else {
        const highestSelectedPrice: string | undefined = sortBy(orders, order =>
          Number(order.perUnitPriceType.unit),
        )[Number(numItems) - 1]?.perUnitPriceType.unit

        if (!highestSelectedPrice) {
          // Do nothing if we cannot get a highestSelectedPrice
          // This happens when there is a form error on numItems
          return
        }

        const defaultMaxPricePerItem = bn(highestSelectedPrice).times(
          MAX_PRICE_PER_ITEM_BUFFER,
        )
        setValue("maxPricePerItem", defaultMaxPricePerItem.toString())
      }
      setFocus("maxPricePerItem")
    } else {
      setContextMaxPricePerItem(undefined)
      setFocus("numItems")
    }
  }, [substituteItemsChecked])

  useUpdateEffect(() => {
    if (substituteItemsChecked) {
      setContextMaxPricePerItem(bn(maxPricePerItem))
    }
  }, [maxPricePerItem])

  useUpdateEffect(() => {
    if (!sweepModeToggled) {
      return
    }
    const desiredNumberOfItems = Number.isNaN(Number(numItems))
      ? 0
      : Math.min(Number(numItems), numEligibleItems)

    setContextDesiredNumberOfItems(desiredNumberOfItems)

    latestSetContextDesiredNumberOfItems.current = desiredNumberOfItems
  }, [numItems])

  useUpdateEffect(() => {
    if (contextDesiredNumberOfItems) {
      setValue(
        "numItems",
        Math.min(
          latestSetContextDesiredNumberOfItems.current
            ? latestSetContextDesiredNumberOfItems.current
            : contextDesiredNumberOfItems,
          numEligibleItems,
        ),
      )
      latestSetContextDesiredNumberOfItems.current = undefined
    }
  }, [contextDesiredNumberOfItems])

  useUpdateEffect(() => {
    const numItemsAsNumber = numItems

    if (sweepModeToggled) {
      if (
        ((isNaN(numItemsAsNumber) || numItemsAsNumber === 0) &&
          numEligibleItems > 0) ||
        numEligibleItems < numItemsAsNumber
      ) {
        setValue(
          "numItems",
          Math.min(numEligibleItems, DEFAULT_NUM_SWEEP_ITEMS),
        )
        return
      }
    }

    if (substituteItemsChecked && formState.dirtyFields.maxPricePerItem) {
      trigger(["numItems", "maxPricePerItem"])
      return
    }

    if (!substituteItemsChecked && formState.dirtyFields.numItems) {
      trigger(["numItems"])
      return
    }
  }, [numEligibleItems, sweepModeToggled])

  useUpdateEffect(() => {
    if (formState.errors.maxPricePerItem) {
      trigger("maxPricePerItem")
    }
  }, [numItems])

  const maxNumItems = Math.min(numEligibleItems, MAX_SWEEP_ITEMS)

  const paymentAssetSymbol = paymentAsset?.symbol ?? ""

  const validateHasEligibleItems = () => {
    if (!formState.isDirty) {
      return true
    }
    if (substituteItemsChecked && numEligibleItems === 0) {
      return t(
        "sweep.numItems.error.noEligibleItemsAtMaxPrice",
        "No eligible items at this max price.",
      )
    }
    if (numEligibleItems === 0) {
      return t("sweep.numItems.error.noEligibleItems", "No eligible items.")
    }
    return true
  }

  const validateHasEnoughEligibleItems = (numItemsProp?: number) => {
    if (!formState.isDirty) {
      return true
    }
    // If prop is passed we are validating on the input itself
    if (numItemsProp !== undefined) {
      if (numItemsProp > numEligibleItems) {
        return t(
          "sweep.numItems.error.notEnoughEligibleItemsWithCta",
          "Not enough eligible items.",
        )
      }
      return true
    }
    // Else we are validating somewhere else in the form
    if (numItems > numEligibleItems) {
      return t(
        "sweep.numItems.error.notEnoughEligibleItems",
        "Not enough eligible items.",
      )
    }
    return true
  }

  const validateMaxSweepItems = (numItemsProp?: number) => {
    if (!formState.isDirty) {
      return true
    }
    if (Number(numItemsProp ?? numItems) > MAX_SWEEP_ITEMS) {
      return t(
        "sweep.numItems.error.maxSweepItems",
        "Maximum number of items for sweep is 30.",
      )
    }
    return true
  }

  const validateIsValidNumber = (value: number) =>
    !bn(value).isNaN() ||
    t("numberInput.invalidAmountPrompt", "Enter a valid number.")

  // TODO: Handle how multiple currencies should show?
  const sweepCtaPrice = `${display(
    totalPrice.totalPricePerSymbol[paymentAssetSymbol]?.price ?? 0,
  )} ${paymentAssetSymbol}`

  const sweepCtaContent = (
    <>
      {substituteItemsChecked ? (
        t("sweep.cta.sweepUpTo", "Sweep up to ")
      ) : (
        <ButtonWithPriceLabelContainer>
          {t("sweep.cta.sweep", "Sweep")}
        </ButtonWithPriceLabelContainer>
      )}
      {sweepCtaPrice}
    </>
  )

  const sweepModeToggle = (
    <>
      <Media lessThan="lg">
        <NetworkUnsupportedGate shouldAuthenticate>
          {({ handleIfNotSupported }) => (
            <Controller
              control={control}
              name="sweepModeToggled"
              render={({ field }) => (
                <Button
                  icon={<BroomIcon />}
                  id={field.name}
                  name={field.name}
                  variant="secondary"
                  onClick={handleIfNotSupported(() => {
                    field.onChange(true)
                    trackToggleSweepMode({ toggled: true })
                  })}
                />
              )}
            />
          )}
        </NetworkUnsupportedGate>
      </Media>
      <Media greaterThanOrEqual="lg">
        <Flex marginRight={{ lg: "32px", xl: "16px", xxl: "32px" }}>
          <Label className="mr-4" htmlFor="sweepModeToggled">
            {t("sweep.toggle", "Sweep")}
          </Label>
          <Controller
            control={control}
            name="sweepModeToggled"
            render={({ field }) => (
              <Switch
                checked={field.value}
                id={field.name}
                name={field.name}
                onCheckedChange={checked => {
                  field.onChange(checked)
                  trackToggleSweepMode({ toggled: checked })
                }}
              />
            )}
          />
        </Flex>
      </Media>
    </>
  )

  const renderNumItemsSlider = (width: string) => (
    <Block marginRight={{ lg: "32px", xl: "16px", xxl: "32px" }}>
      <Controller
        control={control}
        name="numItems"
        render={({ field, fieldState }) => (
          <Tooltip
            content={fieldState.error?.message}
            disabled={!fieldState.error?.message}
          >
            <Flex
              as="span"
              onClick={() => {
                if (validateHasEligibleItems() !== true) {
                  // Do nothing if no eligible items
                  return
                }
                if (
                  validateHasEnoughEligibleItems() !== true ||
                  validateMaxSweepItems() !== true
                ) {
                  field.onChange(maxNumItems)
                }
              }}
            >
              {isLessThanLg && (
                <>
                  <Slider
                    autoFocus
                    className={sliderClassName}
                    error={!!fieldState.error?.message}
                    id={field.name}
                    max={maxNumItems}
                    min={1}
                    ref={field.ref}
                    style={{ width }}
                    value={field.value || 0}
                    onBlur={field.onBlur}
                    onValueChange={field.onChange}
                  />

                  <StyledInput
                    $isSideInput
                    className="w-[120px]"
                    endEnhancer={
                      numItems && (
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          marginLeft="4px"
                        >
                          <Text size="small">
                            {t(
                              "sweep.numItems.input",
                              { 0: "items", one: "item", other: "items" },
                              { count: Number(numItems) },
                            )}
                          </Text>
                        </Flex>
                      )
                    }
                    error={!!fieldState.error?.message}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                </>
              )}
              <Media greaterThanOrEqual="lg">
                <Flex>
                  {!isBetweenLgAndXl && (
                    <Slider
                      autoFocus
                      className={sliderClassName}
                      error={!!fieldState.error?.message}
                      id={field.name}
                      max={maxNumItems}
                      min={1}
                      ref={field.ref}
                      style={{ width }}
                      value={field.value || 0}
                      onBlur={field.onBlur}
                      onValueChange={field.onChange}
                    />
                  )}

                  <StyledInput
                    $isSideInput={!isBetweenLgAndXl}
                    className="w-[60px]"
                    error={!!fieldState.error?.message}
                    overrides={{
                      Input: { style: { textAlign: "center" } },
                    }}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                </Flex>
              </Media>
            </Flex>
          </Tooltip>
        )}
        rules={{
          validate: {
            validateIsValidNumber,
            validateHasEligibleItems,
            validateHasEnoughEligibleItems,
            validateMaxSweepItems,
          },
          required: t(
            "sweep.numItems.error.required",
            "Enter number of items to sweep.",
          ),
          min: {
            value: 1,
            message: t(
              "sweep.numItems.error.minimum",
              "Enter an amount greater than or equal to {{min}}.",
              { min: 1 },
              { forceString: true },
            ),
          },
        }}
      />
    </Block>
  )

  const substituteItemsLabel = (
    <Flex alignItems="center">
      {/* Explicitly not using a label for the checkbox as the tooltip is right next to this,
       which makes it an easy tap target on mobile */}
      <Label className="lg:ml-3">
        <Text className="whitespace-nowrap" size="small">
          {t("sweep.substitute.label", "Substitute items")}
        </Text>
      </Label>
      <InfoIcon
        overrides={{
          Button: {
            style: { marginLeft: "4px" },
          },
          Icon: {
            size: 20,
          },
          Tooltip: { interactive: true },
        }}
        tooltipContent={t(
          "sweep.substitute.moreInfo",
          "Unavailable items will be substituted with the next cheapest items below your specified max price. {{learnMoreLink}}",
          {
            learnMoreLink: (
              <Link href="https://support.opensea.io/articles/8866969">
                Learn more
              </Link>
            ),
          },
        )}
      />
    </Flex>
  )

  const substituteItemsCheckbox = (
    <Controller
      control={control}
      name="substituteItemsChecked"
      render={({ field }) => (
        <Checkbox
          checked={field.value}
          id={field.name}
          name={field.name}
          onCheckedChange={checked => {
            field.onChange(checked)
            trackSubstituteItemsChecked({ checked })
          }}
        />
      )}
    />
  )

  const maxPricePerItemInput = (
    <Block
      alignItems="center"
      className="w-full"
      display="flex"
      justifyContent={{ _: "space-between", lg: "flex-start" }}
    >
      <Label className="mr-3" htmlFor="maxPricePerItem">
        <Text size="small">
          {t("sweep.maxPrice.label", "Max price per item")}
        </Text>
      </Label>
      <Tooltip
        content={formState.errors.maxPricePerItem?.message}
        disabled={!formState.errors.maxPricePerItem?.message}
      >
        <Input
          className="w-[120px]"
          endEnhancer={paymentAssetSymbol}
          error={!!formState.errors.maxPricePerItem?.message}
          {...register("maxPricePerItem", {
            validate: {
              validateRequiredWhenSubstituteItemsChecked: value =>
                !substituteItemsChecked ||
                value.trim() !== "" ||
                t(
                  "sweep.maxPrice.error.required",
                  "Enter maximum price for substitution.",
                ),
              validateIsValidNumberWhenSubstituteItemsChecked: value =>
                !substituteItemsChecked || validateIsValidNumber(Number(value)),
              validateHasEnoughEligibleItems: _ =>
                validateHasEnoughEligibleItems(undefined),
            },
            min: {
              value: 0,
              message: t(
                "sweep.maxPrice.error.minimum",
                "Enter an amount greater than or equal to {{min}}.",
                { min: 0 },
                { forceString: true },
              ),
            },
          })}
        />
      </Tooltip>
    </Block>
  )

  return (
    <BulkPurchaseModal
      fillType="SWEEP"
      maxOrdersToFill={substituteItemsChecked ? Number(numItems) : undefined}
      orderToQuantity={orderToQuantity}
      orders={includedOrders}
      paymentMethod="crypto"
    >
      <Media lessThan="lg">
        {sweepModeToggle}

        <BottomSheet
          closeOnOverlayClick={false}
          isOpen={sweepModeToggled}
          overrides={{
            Overlay: {
              props: {
                backgroundColor: "initial",
                style: { top: "initial" },
                zIndex: Z_INDEX.MODAL - 1,
              },
            },
          }}
          onClose={() => setValue("sweepModeToggled", false)}
        >
          <BottomSheet.Body
            lockBodyScroll={false}
            paddingBottom={{ _: "0px", sm: "0px" }}
          >
            <FlexColumn className="gap-4">
              <FormControl
                className="mr-10"
                error={formState.errors.numItems?.message}
                hideLabel
                label="numItems"
              >
                {renderNumItemsSlider("100%")}
              </FormControl>
              <SpaceBetween>
                {substituteItemsLabel}
                {substituteItemsCheckbox}
              </SpaceBetween>

              {substituteItemsChecked && (
                <FormControl
                  error={formState.errors.maxPricePerItem?.message}
                  hideLabel
                  label="maxPricePerItem"
                >
                  {maxPricePerItemInput}
                </FormControl>
              )}
            </FlexColumn>
          </BottomSheet.Body>
          <BottomSheet.Footer>
            <BulkPurchaseModal.Trigger disabled={!isEmpty(formState.errors)}>
              {sweepCtaContent}
            </BulkPurchaseModal.Trigger>
          </BottomSheet.Footer>
        </BottomSheet>
      </Media>
      <Media greaterThanOrEqual="lg">
        {mediaClassNames => (
          <Form className={classNames("w-full", mediaClassNames)}>
            <SpaceBetween className="w-full">
              <Flex alignItems="center">
                {sweepModeToggle}

                {sweepModeToggled && (
                  <>
                    {renderNumItemsSlider("240px")}

                    <Flex
                      alignItems="center"
                      marginRight={{ lg: "32px", xl: "16px", xxl: "32px" }}
                    >
                      {substituteItemsCheckbox}
                      {substituteItemsLabel}
                    </Flex>

                    {substituteItemsChecked && maxPricePerItemInput}
                  </>
                )}
              </Flex>

              {sweepModeToggled && (
                <Block>
                  <BulkPurchaseModal.Trigger
                    disabled={!isEmpty(formState.errors)}
                    onClick={() => {
                      trackClickSweepButton({
                        numItems: Number(numItems),
                        substituteItems: substituteItemsChecked,
                      })
                    }}
                  >
                    {sweepCtaContent}
                  </BulkPurchaseModal.Trigger>
                </Block>
              )}
            </SpaceBetween>
          </Form>
        )}
      </Media>
    </BulkPurchaseModal>
  )
}

const StyledInput = styled(Input)<{ $isSideInput: boolean }>`
  justify-content: center;
  min-width: 36px;
  text-align: center;

  ${props =>
    props.$isSideInput &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}
`
