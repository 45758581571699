import React, { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { CollectionTrackingContext_collection$key } from "@/lib/graphql/__generated__/CollectionTrackingContext_collection.graphql"
import { TrackingContextProvider } from "../TrackingContext.react"

type CollectionContext = {
  relayId: string
  slug: string
  isVerified: boolean
  defaultChain: string
}

export type CollectionTrackingContext = {
  Collection: CollectionContext | undefined
}

type CollectionTrackingContextProviderProps = {
  collection: CollectionTrackingContext_collection$key | null
  children: React.ReactNode
}

export const CollectionTrackingContextProvider = ({
  collection: collectionDataKey,
  children,
}: CollectionTrackingContextProviderProps) => {
  const data = useFragment(
    graphql`
      fragment CollectionTrackingContext_collection on CollectionType {
        relayId
        slug
        isVerified
        isCollectionOffersEnabled
        defaultChain {
          identifier
        }
      }
    `,
    collectionDataKey,
  )

  const properties: CollectionContext | undefined = useMemo(() => {
    if (!data) {
      return undefined
    }

    const {
      relayId,
      slug,
      isVerified,
      isCollectionOffersEnabled,
      defaultChain,
    } = data

    return {
      relayId,
      slug,
      isVerified,
      isCollectionOffersEnabled,
      defaultChain: defaultChain.identifier,
    }
  }, [data])

  return (
    <TrackingContextProvider name="Collection" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
