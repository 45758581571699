import React, { useMemo } from "react"
import { classNames } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import {
  BottomActionBar,
  BOTTOM_ACTION_BAR_HEIGHT,
  BOTTOM_ACTION_BAR_HEIGHT_PX,
} from "@/components/common/BottomActionBar/BottomActionBar.react"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { Flex } from "@/design-system/Flex"
import { getSelectedTraitFromSearchStateStringTraits } from "@/features/orders/components/CollectionOfferMultiModal/utils"
import { MakeCollectionOfferButton } from "@/features/orders/components/MakeCollectionOfferButton"
import { SweepForm } from "@/features/sweep/components/SweepForm"
import {
  useResetSweepForm,
  useSweepFormDesiredNumberOfItems,
  useSweepFormMaxPricePerItem,
} from "@/features/sweep/SweepContextProvider.react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { Search } from "@/hooks/useSearch"
import { CollectionFooter_collection$key } from "@/lib/graphql/__generated__/CollectionFooter_collection.graphql"

type Props = {
  collection: CollectionFooter_collection$key | null
  showSweepForm: boolean
  stringTraits: Search["stringTraits"]
  isOpen: boolean
}

export const COLLECTION_FOOTER_HEIGHT = BOTTOM_ACTION_BAR_HEIGHT
export const COLLECTION_FOOTER_HEIGHT_PX = BOTTOM_ACTION_BAR_HEIGHT_PX

export const CollectionFooter = ({
  collection: collectionDataKey,
  showSweepForm,
  stringTraits,
  isOpen,
}: Props) => {
  const collection = useFragment(
    graphql`
      fragment CollectionFooter_collection on CollectionType {
        isCollectionOffersEnabled
        isTraitOffersEnabled
        slug
        defaultChain {
          isTradingEnabled
        }
      }
    `,
    collectionDataKey,
  )

  const { setDesiredNumberOfItems } = useSweepFormDesiredNumberOfItems()
  const { setMaxPricePerItem } = useSweepFormMaxPricePerItem()
  const resetSweepForm = useResetSweepForm()

  const { selectedCardVariant } = useAccountOrCollectionPageContext()

  const defaultChain = collection?.defaultChain
  const isTradingEnabled = defaultChain?.isTradingEnabled

  const showCollectionOfferButton =
    collection?.isCollectionOffersEnabled && isTradingEnabled

  const resetContextValues = () => {
    setDesiredNumberOfItems(undefined)
    setMaxPricePerItem(undefined)
  }

  useUpdateEffect(() => {
    // Reset form and context values when collection slug changes on navigation
    // from one collection page to another. The collection footer is not unmounted
    // when both collections are available in relay store
    if (collection?.slug) {
      resetSweepForm?.()
      resetContextValues()
    }
  }, [collection?.slug])

  useMountEffect(() => {
    // Reset form and context values when collection footer is unmounted
    return () => {
      resetSweepForm?.()
      resetContextValues()
    }
  })

  const selectedTrait = useMemo(
    () => getSelectedTraitFromSearchStateStringTraits({ stringTraits }),
    [stringTraits],
  )

  if (selectedCardVariant === "natural") {
    return null
  }

  return (
    <BottomActionBar
      className={classNames(
        "justify-center",
        showSweepForm ? "sm:justify-between" : "sm:justify-end",
      )}
      isOpen={isOpen}
    >
      {showSweepForm && (
        <Flex
          className="items-center sm:w-full"
          justifyContent={{ _: "flex-end", lg: "flex-start" }}
          marginRight={{ _: "16px", lg: "0px" }}
        >
          <SweepForm />
        </Flex>
      )}

      {showCollectionOfferButton && (
        <MakeCollectionOfferButton
          overrides={{ Button: { variant: "primary" } }}
          selectedTrait={
            collection.isTraitOffersEnabled ? selectedTrait : undefined
          }
          slug={collection.slug}
        />
      )}
    </BottomActionBar>
  )
}
