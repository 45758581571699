import React, { useState, Suspense, useCallback, useEffect } from "react"
import {
  Icon,
  IconProps,
  Media,
  useIsLessThanMd,
  UnstyledButton,
  Text,
  Label,
  VerticalAligned,
  classNames,
  SpaceBetween,
  FlexColumn,
  Input,
  Alert,
  Flex,
} from "@opensea/ui-kit"
import { add, addMonths } from "date-fns"
import { Controller } from "react-hook-form"
import { useFragment, useRelayEnvironment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { BlockchainActionOnEnd } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { CreateCollectionOfferActionModal } from "@/components/blockchain/orders/CreateCollectionOfferActionModal"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { commitNewCollectionOfferInTranche } from "@/components/offers/BidDepthTable/utils"
import { AddFundsModalContent } from "@/components/trade/AddFundsModal"
import {
  getPaymentAssetOptions,
  PaymentTokenInput,
} from "@/components/trade/PaymentTokenInput"
import {
  LazyTraitSelector,
  SelectedTrait,
} from "@/components/traits/TraitSelector"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { DatePicker } from "@/design-system/DatePicker"
import { Form } from "@/design-system/Form"
import { Item } from "@/design-system/Item"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { Select } from "@/design-system/Select"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useReactivatableListingsWarningContent } from "@/features/assets/components/ListingWarning/useReactivatableListingsWarningContent.react"
import {
  ExpirationOption,
  useNewCollectionOfferExpirationOptions,
} from "@/features/orders/constants"
import { useCollectionOfferContext } from "@/features/orders/hooks/useCollectionOfferContext"
import {
  MAX_COLLECTION_OFFER_QUANTITY,
  useOfferModalAdapter,
} from "@/features/orders/hooks/useOfferModalAdapter"
import {
  PERCENTAGE_ABOVE_FLOOR_WARNING_THRESHOLD,
  PriceWarningModal,
} from "@/features/sell/components/SellFlowMultiStepModal/PriceWarningModal.react"
import { useNewOfferPrecision } from "@/hooks/useFlag"
import { useForm } from "@/hooks/useForm"
import { useIsMountedRef } from "@/hooks/useIsMounted"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionOfferModal_account$key } from "@/lib/graphql/__generated__/CollectionOfferModal_account.graphql"
import { CollectionOfferModal_collectionData$key } from "@/lib/graphql/__generated__/CollectionOfferModal_collectionData.graphql"
import { CollectionOfferModal_tradeLimits$key } from "@/lib/graphql/__generated__/CollectionOfferModal_tradeLimits.graphql"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import {
  BigNumber,
  bn,
  display,
  displayUSD,
  getOfferPricePrecision,
  isValidNumericInput,
  MAX_DECIMALS_FOR_OFFERS,
  normalizePriceDisplay,
  offerPriceRounding,
  percentageDifference,
} from "@/lib/helpers/numberUtils"
import { CollectionOfferProvider } from "../CollectionOfferProvider"
import {
  trackOpenOfferModal,
  trackSubmitOfferModalForm,
  trackOfferModalTraitSelected,
} from "./analytics"
import { CollectionOfferDetails } from "./components/CollectionOfferDetails"
import {
  OfferPriceDetails,
  OfferPriceDetailsSkeleton,
} from "./components/OfferPriceDetails"
import {
  SetToBestOfferButton,
  SetToBestOfferButtonSkeleton,
} from "./components/SetToBestOfferButton"
import {
  DEFAULT_COLLECTION_EXPIRATION_OPTION,
  DEFAULT_COLLECTION_EXPIRATION_OPTION_LABEL,
  OfferModalFormData,
} from "./OfferModal.react"

type Props = {
  collection: CollectionOfferModal_collectionData$key | null
  tradeLimits: CollectionOfferModal_tradeLimits$key
  account: CollectionOfferModal_account$key | null
  initialPricePerUnit?: string
  onClose: () => unknown
}

export const CollectionOfferModal = ({
  collection: collectionDataKey,
  tradeLimits: tradeLimitsDataKey,
  account: accountDataKey,
  initialPricePerUnit,
  onClose,
}: Props) => {
  const connectedAddress = useConnectedAddress()
  const isMountedRef = useIsMountedRef()
  const collection = useFragment(
    graphql`
      fragment CollectionOfferModal_collectionData on CollectionType {
        isTraitOffersEnabled
        name
        slug
        relayId
        statsV2 {
          floorPrice {
            usd
            symbol
          }
        }
        ...OfferPriceDetails_collection
        ...useOfferModalAdapter_collection
        ...CollectionOfferDetails_collection
      }
    `,
    collectionDataKey,
  )

  const tradeLimits = useFragment(
    graphql`
      fragment CollectionOfferModal_tradeLimits on TradeLimitsType {
        minimumBidUsdPrice
        ...useOfferModalAdapter_tradeLimits
      }
    `,
    tradeLimitsDataKey,
  )

  // TODO(@laurafiuza): This logic is repeated 3x
  // Fast follow is share this logic in a new component
  // https://linear.app/opensea/issue/MRKT-3724/share-logic-for-offer-protection-since-there-is-identical-code
  const account = useFragment(
    graphql`
      fragment CollectionOfferModal_account on AccountType {
        offerProtectionEnabled
      }
    `,
    accountDataKey,
  )

  const t = useTranslate("orders")

  const { showSuccessMessage, showErrorMessage } = useToasts()
  const [traitsPanelIsOpen, setTraitsPanelIsOpen] = useState(false)
  const [priceDifference, setPriceDifference] = useState<BigNumber>(bn(0))
  const [durationPanelIsOpen, setDurationPanelIsOpen] = useState(
    !collection?.isTraitOffersEnabled,
  )
  const isNewOfferPrecisionRequired = useNewOfferPrecision()
  const { onNext, onReplace } = useMultiStepFlowContext()
  const { selectedTrait, setSelectedTrait, defaultOfferPrice } =
    useCollectionOfferContext()
  const [showHiddenListingWarning, setShowHiddenListingWarning] =
    useState(false)
  const [bidExpirationValue, setBidExpirationValue] = useState<
    ExpirationOption["value"]
  >(DEFAULT_COLLECTION_EXPIRATION_OPTION)
  const [bidExpirationLabel, setBidExpirationLabel] = useState<
    ExpirationOption["label"]
  >(DEFAULT_COLLECTION_EXPIRATION_OPTION_LABEL)
  const showOfferProtectionAlert = Boolean(account?.offerProtectionEnabled)
  const offerProtectionInfo = (
    <>
      {t(
        "collectionOfferModal.protection.body",
        "Collection offers come with protections. ",
      )}
      <Link href="https://support.opensea.io/articles/8866980">
        {t("collectionOfferModal.protection.learnMoreLink", "Learn more")}
      </Link>
    </>
  )
  const offerProtectionInfoProps: IconProps = {
    className: "text-blue-3 mr-2",
    value: "security",
  }

  const offerPrice = t("collectionOfferModal.offerPrice", "Offer price")

  const {
    checkForHiddenListings,
    getBalance,
    getCurrentPaymentAsset,
    getPaymentAssets,
    getMinBid,
    getTotalPrice,
    isFungible,
    maxQuantity,
    collectionAssetContract,
    eventParams,
  } = useOfferModalAdapter({
    asset: null,
    collection,
    tradeData: null,
    tradeLimitsDataKey: tradeLimits,
  })

  const collectionExpirationOptions = useNewCollectionOfferExpirationOptions()
  const defaultExpirationDate = collectionExpirationOptions.find(
    option => option.value === DEFAULT_COLLECTION_EXPIRATION_OPTION,
  )
  const paymentAssets = getPaymentAssets()
  const [selectedPaymentAssetRelayId, setSelectedPaymentAssetRelayId] =
    useState<string>(() => paymentAssets[0].relayId)

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState,
    setError,
    trigger,
  } = useForm<OfferModalFormData>({
    mode: "onChange",
    defaultValues: {
      paymentAssetRelayId: selectedPaymentAssetRelayId,
      bidExpiration:
        defaultExpirationDate?.date ?? add(new Date(), { minutes: 30 }),
      quantity: "1",
      pricePerUnit: initialPricePerUnit ?? "",
    },
  })

  const [selectedPaymentAssetBalance, setSelectedPaymentAssetBalance] =
    useState<BigNumber>(bn(0))
  const fetchAndSetBalance = async (paymentAssetId?: string) => {
    const assetId = paymentAssetId ?? selectedPaymentAssetRelayId
    const balance = await getBalance(assetId)
    setSelectedPaymentAssetBalance(balance)
  }

  const pricePerUnitInput = watch("pricePerUnit")
  useUpdateEffect(() => {
    if (pricePerUnitInput !== "") {
      trigger("pricePerUnit")
    }
  }, [selectedPaymentAssetBalance])

  const setHiddenListingWarning = async () => {
    const shouldShowHiddenListingWarning = await checkForHiddenListings()
    if (isMountedRef.current) {
      setShowHiddenListingWarning(shouldShowHiddenListingWarning)
    }
  }

  const isMdScreen = !useIsLessThanMd()

  const quantity = watch("quantity")
  const bidExpiration = watch("bidExpiration")
  const paymentAsset = getCurrentPaymentAsset(selectedPaymentAssetRelayId)
  const ethPrice = paymentAsset.ethPrice
  const usdSpotPrice = paymentAsset.usdPrice
  const symbol = paymentAsset.symbol
  const chain = paymentAsset.chain.identifier
  const roundedPricePerUnit = offerPriceRounding(pricePerUnitInput)
  const pricePerUnit: BigNumber = roundedPricePerUnit
  const totalPrice = getTotalPrice({
    quantity,
    pricePerUnit: pricePerUnit.toString(),
  })

  const totalUsdPrice = totalPrice ? bn(totalPrice).times(usdSpotPrice) : null
  const perUnitUsdPrice = !pricePerUnit.isNaN()
    ? pricePerUnit.times(usdSpotPrice)
    : null
  const defaultCustomExpiration = add(new Date(), { minutes: 30 })
  const defaultMaxOfferDuration = addMonths(new Date(), 1)
  const [maxOfferDuration, setMaxOfferDuration] = useState(
    defaultMaxOfferDuration,
  )
  const showAddFunds = selectedPaymentAssetBalance.isLessThan(
    pricePerUnit.times(bn(quantity)),
  )
  const hasFloorPriceWarning =
    formState.errors.pricePerUnit?.type === "floorWarning"
  const paymentAssetOptions = getPaymentAssetOptions(paymentAssets)

  const onSelectTrait = useCallback(
    (selection?: SelectedTrait) => {
      if (!selection) {
        setSelectedTrait(null)
        setMaxOfferDuration(defaultMaxOfferDuration)
        setValue("bidExpiration", defaultCustomExpiration)
        return
      }
      trackOfferModalTraitSelected({
        ...eventParams,
        selectedTrait: selection,
      })
      setSelectedTrait(selection)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventParams, setValue],
  )

  const renderPriceSide = useCallback(() => {
    const heading = `${
      totalPrice ? display(totalPrice) : EMPTY_PRICE_DISPLAY
    } ${symbol}`
    return (
      <>
        <Item.Title asChild>
          {isMdScreen ? (
            <Text.Heading size="small">{heading}</Text.Heading>
          ) : (
            <Text.Body weight="semibold">{heading}</Text.Body>
          )}
        </Item.Title>
        <Item.Description size={isMdScreen ? undefined : "tiny"}>
          {totalUsdPrice && !totalUsdPrice.isNaN()
            ? `$${displayUSD(totalUsdPrice)}`
            : EMPTY_PRICE_DISPLAY}
        </Item.Description>
      </>
    )
  }, [symbol, totalPrice, totalUsdPrice, isMdScreen])

  const durationLabelContent = (
    <>
      <Text.Body size="medium" weight="semibold">
        {t("collectionOfferModal.labelDuration.label", "Duration")}
      </Text.Body>
      {collection?.isTraitOffersEnabled && (
        <Flex className="overflow-hidden">
          {!durationPanelIsOpen && (
            <Info className="ml-8 mr-4" size="medium">
              {bidExpirationLabel}
            </Info>
          )}
          <Icon value={durationPanelIsOpen ? "remove" : "add"} />
        </Flex>
      )}
    </>
  )

  const durationLabel = (
    <SpaceBetween
      asChild={collection?.isTraitOffersEnabled}
      className={classNames("mb-0 w-full", durationPanelIsOpen && "mb-2")}
    >
      {collection?.isTraitOffersEnabled ? (
        <UnstyledButton
          onClick={() => {
            if (collection.isTraitOffersEnabled) {
              setDurationPanelIsOpen(!durationPanelIsOpen)
            }
          }}
        >
          {durationLabelContent}
        </UnstyledButton>
      ) : (
        durationLabelContent
      )}
    </SpaceBetween>
  )

  const traitsLabel = (
    <SpaceBetween
      asChild
      className={classNames("mb-0 w-full", traitsPanelIsOpen && "mb-2")}
    >
      <UnstyledButton onClick={() => setTraitsPanelIsOpen(!traitsPanelIsOpen)}>
        <Text.Body size="medium" weight="semibold">
          {t("collectionOfferModal.traitSelector.label", "Trait")}
        </Text.Body>
        <Flex className="overflow-hidden">
          {!traitsPanelIsOpen && selectedTrait && (
            <Info
              className="ml-8 mr-4"
              size="medium"
            >{`${selectedTrait.key}: ${selectedTrait.value}`}</Info>
          )}
          <Icon value={traitsPanelIsOpen ? "remove" : "add"} />
        </Flex>
      </UnstyledButton>
    </SpaceBetween>
  )

  const numOffersInfoText = t(
    "collectionOfferModal.acceptedSeparately",
    "Offers can be accepted separately",
  )

  const numOffersInfo = <Item.Description>{numOffersInfoText}</Item.Description>

  const environment = useRelayEnvironment()
  const handleOrderCreated: BlockchainActionOnEnd = () => {
    if (connectedAddress && collection) {
      commitNewCollectionOfferInTranche(environment, {
        pricePerUnit: bn(ethPrice).multipliedBy(pricePerUnit),
        quantity: quantity ? bn(quantity) : bn(1),
        address: connectedAddress,
        collectionRelayId: collection.relayId,
        selectedTrait,
      })
    }
    const submitted = t(
      "collectionOfferModal.submitSuccess",
      `Your offer has been submitted. {{link}}`,
      {
        link: (
          <Link href="/account/offers">
            {t("collectionOfferModal.offers.link", "View your offers")}
          </Link>
        ),
      },
    )
    onClose()
    showSuccessMessage(<Block>{submitted}</Block>)
  }

  const onSubmit = handleSubmit(async formData => {
    trackSubmitOfferModalForm({
      ...eventParams,
      ...formData,
      selectedTrait,
    })

    const onError = () => {
      onClose()
      showErrorMessage(
        t(
          "collectionOfferModal.create.error",
          "Something went wrong while creating an offer",
        ),
      )
    }

    const totalPrice = pricePerUnit.times(bn(formData.quantity))
    const priceInput = {
      paymentAsset: paymentAsset.relayId,
      amount: totalPrice.toString(),
    }
    const closedAt = bidExpiration.toISOString()

    if (!priceDifference.isZero()) {
      onNext(
        <MakeOfferWarningModal
          percentageBelowFloorPrice={priceDifference.toString()}
          renderMakeOfferModal={() =>
            collection?.slug && collectionAssetContract ? (
              <CreateCollectionOfferActionModal
                assetContract={collectionAssetContract}
                closedAt={closedAt}
                collection={collection.slug}
                price={priceInput}
                quantity={quantity}
                trait={
                  selectedTrait
                    ? {
                        name: selectedTrait.key,
                        value: selectedTrait.value,
                      }
                    : undefined
                }
                onEnd={handleOrderCreated}
                onError={onError}
              />
            ) : (
              <CollectionOfferModal
                account={accountDataKey}
                collection={collectionDataKey}
                initialPricePerUnit={pricePerUnitInput}
                tradeLimits={tradeLimitsDataKey}
                onClose={onClose}
              />
            )
          }
          onClose={() => (
            <CollectionOfferModal
              account={accountDataKey}
              collection={collectionDataKey}
              initialPricePerUnit={pricePerUnitInput}
              tradeLimits={tradeLimitsDataKey}
              onClose={onClose}
            />
          )}
        />,
      )
    } else if (collection?.slug && collectionAssetContract) {
      onNext(
        <CreateCollectionOfferActionModal
          assetContract={collectionAssetContract}
          closedAt={closedAt}
          collection={collection.slug}
          price={priceInput}
          quantity={quantity}
          trait={
            selectedTrait
              ? {
                  name: selectedTrait.key,
                  value: selectedTrait.value,
                }
              : undefined
          }
          onEnd={handleOrderCreated}
          onError={onError}
        />,
      )
    }
  })

  const MakeOfferWarningModal = ({
    percentageBelowFloorPrice,
    renderMakeOfferModal,
    onClose,
  }: {
    percentageBelowFloorPrice: string
    renderMakeOfferModal: (onNext: () => unknown) => React.ReactNode
    onClose: () => unknown
  }) => {
    const { onNext } = useMultiStepFlowContext()
    const t = useTranslate("orders")
    return (
      <PriceWarningModal
        priceWarningActionMessage={t("makeOffer", "Make offer")}
        priceWarningHeader={t("makeHighOffer", "Make high offer?")}
        priceWarningMessage={t(
          "makeHighOfferWarning",
          "This offer is {{percent}} above the floor price for this collection.",
          {
            percent: (
              <b>
                {normalizePriceDisplay(percentageBelowFloorPrice.toString(), 0)}
                %
              </b>
            ),
          },
        )}
        onConfirm={() => onNext(renderMakeOfferModal(onClose))}
      />
    )
  }

  useMountEffect(() => {
    fetchAndSetBalance()
    setHiddenListingWarning()
    trackOpenOfferModal(eventParams)

    if (defaultOfferPrice) {
      setValue(
        "pricePerUnit",
        offerPriceRounding(defaultOfferPrice).toString(),
        {
          shouldValidate: false,
        },
      )
    }
  })

  useEffect(() => {
    if (formState.errors.pricePerUnit) {
      // only add these errors if there is no existing error on the field
      return
    }
    const collectionFloor = bn(collection?.statsV2.floorPrice?.usd ?? "0")
    const floorPriceInUsd = selectedTrait
      ? BigNumber.max(bn(selectedTrait.floor?.usd ?? "0"), collectionFloor)
      : collectionFloor
    if (
      perUnitUsdPrice &&
      percentageDifference(floorPriceInUsd, perUnitUsdPrice).isGreaterThan(
        PERCENTAGE_ABOVE_FLOOR_WARNING_THRESHOLD,
      )
    ) {
      setPriceDifference(percentageDifference(floorPriceInUsd, perUnitUsdPrice))
    } else if (
      perUnitUsdPrice &&
      floorPriceInUsd.isGreaterThan(0) &&
      perUnitUsdPrice.isGreaterThan(floorPriceInUsd)
    ) {
      setError("pricePerUnit", {
        type: "floorWarning",
        message: t(
          "collectionOfferModal.error",
          `Offer is above the floor price for this {{selectedTrait}}.`,
          {
            selectedTrait: selectedTrait
              ? t("offers.trait.description.trait", "trait")
              : t("offers.trait.description.collection", "collection"),
          },
          {
            forceString: true,
          },
        ),
      })
    }
  }, [
    collection?.statsV2.floorPrice?.usd,
    formState.errors.pricePerUnit,
    perUnitUsdPrice,
    selectedTrait,
    setError,
    t,
    priceDifference,
  ])

  const preSaleReactivatableListingsWarning =
    useReactivatableListingsWarningContent({
      variant: "prePurchase",
      isMultipleItems: false,
    })

  return (
    <Modal.Form
      autoComplete="off"
      maxHeight="100vh"
      maxWidth="100vw"
      onSubmit={onSubmit}
    >
      <Modal.Header>
        <Modal.Header.Title>
          <Flex>
            {t(
              "collectionOfferModal.makeOffer.collection.title",
              "Make collection offer",
            )}
            <InfoIcon
              overrides={{
                Button: { style: { marginLeft: "8px" } },
                Icon: { size: 16 },
                Tooltip: { placement: "bottom" },
              }}
              tooltipContent={
                <>
                  {selectedTrait
                    ? t(
                        "collectionOfferModal.tooltipSelectedTrait",
                        "This offer can be accepted once for any single item in this collection with the selected trait.",
                      )
                    : t(
                        "collectionOfferModal.tooltipNoSelectedTrait",
                        "A collection offer can be accepted once for any single item in this collection.",
                      )}
                  <br />
                  <Link href="https://support.opensea.io/articles/8866980">
                    {t("offerModal.learnMore", "Learn more")}
                  </Link>
                </>
              }
            />
          </Flex>
        </Modal.Header.Title>
      </Modal.Header>

      <Modal.Body>
        {showHiddenListingWarning && (
          <Alert className="mb-2">
            <Alert.Icon color="warning" value="error" />
            <Alert.Title>{preSaleReactivatableListingsWarning}</Alert.Title>
          </Alert>
        )}
        <Block>
          <Block marginBottom="8px">
            <Text.Body asChild size="medium" weight="semibold">
              <h5>{offerPrice}</h5>
            </Text.Body>
            {collection && (
              <Suspense fallback={<OfferPriceDetailsSkeleton />}>
                <OfferPriceDetails
                  collection={collection}
                  selectedTrait={selectedTrait}
                />
              </Suspense>
            )}
          </Block>

          <Flex className="relative mb-4 flex-col items-start sm:flex-row">
            <Block className="w-full" flexGrow={1}>
              <Controller
                control={control}
                name="pricePerUnit"
                render={({ field }) => {
                  return (
                    <PaymentTokenInput
                      captionLeft={<></>}
                      captionRight={<></>}
                      error={
                        !hasFloorPriceWarning
                          ? formState.errors.pricePerUnit
                          : undefined
                      }
                      hideLabel
                      label={t(
                        "collectionOfferModal.form.offerAmount.label",
                        "Price",
                      )}
                      name={field.name}
                      overrides={{
                        Select: {
                          style: {
                            width:
                              paymentAssetOptions.length === 1
                                ? "88px"
                                : "118px",
                          },
                        },
                      }}
                      paymentAssetOptions={paymentAssetOptions}
                      paymentAssetRelayId={selectedPaymentAssetRelayId}
                      placeholder={t(
                        "collectionOfferModal.form.offerAmount.placeholder",
                        "Price",
                      )}
                      price={field.value}
                      quantity={Number(quantity)}
                      warning={
                        hasFloorPriceWarning
                          ? formState.errors.pricePerUnit?.message
                          : undefined
                      }
                      onChange={price => {
                        const value = price.replace(",", ".")
                        field.onChange(value)
                      }}
                      onChangePaymentAsset={relayId => {
                        setSelectedPaymentAssetRelayId(relayId)
                        fetchAndSetBalance(relayId)
                      }}
                    />
                  )
                }}
                rules={{
                  required: true,
                  validate: {
                    pricePrecision: value => {
                      const decimalPlaces =
                        value.toString().split(".")[1]?.length || 0
                      const maxAllowedDecimals = isNewOfferPrecisionRequired
                        ? getOfferPricePrecision(bn(value))
                        : MAX_DECIMALS_FOR_OFFERS
                      if (decimalPlaces > maxAllowedDecimals) {
                        return t(
                          "collectionOfferModal.form.pricePrecision.error",
                          `Maximum {{decimals}} decimals places allowed`,
                          { decimals: maxAllowedDecimals },
                          { forceString: true },
                        )
                      }
                      return true
                    },
                    insufficientFunds: value => {
                      // We only gate funds on Ethereum as other chains have the ability to deposit/bridge assets, but on
                      // Ethereum we don't

                      const insufficientFunds =
                        chain === getEthereumChain()
                          ? selectedPaymentAssetBalance.isLessThan(
                              bn(value).times(bn(quantity)),
                            )
                          : false

                      if (insufficientFunds) {
                        return t(
                          "collectionOfferModal.form.insufficientFunds.error",
                          `You don't have enough {{symbol}}`,
                          { symbol },
                          { forceString: true },
                        )
                      }

                      return true
                    },
                    other: value => {
                      const minBidPrice = getMinBid(selectedPaymentAssetRelayId)
                      const perUnitPrice = bn(value)
                      const minimumPriceInUsd = tradeLimits.minimumBidUsdPrice

                      if (
                        perUnitPrice.isNaN() ||
                        !isValidNumericInput(value, paymentAsset.decimals)
                      ) {
                        return t(
                          "collectionOfferModal.form.validAmount.error",
                          "Please enter a valid amount.",
                        )
                      }

                      if (minBidPrice && perUnitPrice.isLessThan(minBidPrice)) {
                        if (minimumPriceInUsd) {
                          if (isFungible) {
                            return t(
                              "collectionOfferModal.form.fungible.minPrice",
                              `Offer must be at least the minimum price per unit of \${{minPriceUSD}} USD ({{minPrice}} {{symbol}})`,
                              {
                                minPriceUSD: displayUSD(minimumPriceInUsd),
                                minPrice: display(minBidPrice, symbol),
                                symbol,
                              },
                              { forceString: true },
                            )
                          }
                          return t(
                            "collectionOfferModal.form.minPrice",
                            `Offer must be at least the minimum price of \${{minPriceUSD}} USD ({{minPrice}} {{symbol}})`,
                            {
                              minPriceUSD: displayUSD(minimumPriceInUsd),
                              minPrice: display(minBidPrice, symbol),
                              symbol,
                            },
                            { forceString: true },
                          )
                        }
                      }

                      return true
                    },
                  },
                }}
              />
            </Block>
            {formState.errors.pricePerUnit?.type === "insufficientFunds" && (
              <Button
                className="w-full sm:ml-2 sm:w-auto"
                icon="refresh"
                variant="secondary"
                onClick={() => fetchAndSetBalance()}
              />
            )}
            {collection && (
              <Suspense fallback={<SetToBestOfferButtonSkeleton />}>
                <SetToBestOfferButton
                  collectionSlug={collection.slug}
                  selectedTrait={selectedTrait}
                  onClick={(offer: string, symbol?: string) => {
                    const paymentAsset = paymentAssetOptions.find(
                      it => it.label == symbol,
                    )
                    if (paymentAsset) {
                      setSelectedPaymentAssetRelayId(paymentAsset.value)
                      fetchAndSetBalance(paymentAsset.value)
                    }
                    setValue(
                      "pricePerUnit",
                      offerPriceRounding(offer, BigNumber.ROUND_UP).toString(),
                      {
                        shouldValidate: true,
                      },
                    )
                  }}
                />
              </Suspense>
            )}
          </Flex>

          <Form.Control
            className={collection?.isTraitOffersEnabled ? "mb-10" : "mb-4"}
            error={formState.errors.quantity?.message}
            hideLabel
            // The actual label is below
            label={undefined}
          >
            <Item variant="unstyled">
              <Item.Content className="items-start">
                <Media greaterThanOrEqual="sm">
                  <FlexColumn>
                    <Item.Title>
                      <Text weight="semibold">
                        <Label htmlFor="quantity">
                          {t("collectionOfferModal.numOffers", "Quantity")}
                        </Label>
                      </Text>
                    </Item.Title>
                    {numOffersInfo}
                  </FlexColumn>
                </Media>
                <Media lessThan="sm">
                  <Flex className="items-center">
                    <Item.Title>
                      <Text weight="semibold">
                        <Label htmlFor="quantity">
                          {t("collectionOfferModal.numOffers", "Quantity")}
                        </Label>
                      </Text>
                    </Item.Title>
                    <InfoIcon
                      overrides={{
                        Button: { style: { marginLeft: "8px" } },
                        Icon: { size: 16 },
                      }}
                      tooltipContent={numOffersInfoText}
                    />
                  </Flex>
                </Media>
              </Item.Content>

              <Item.Side>
                <Input
                  {...register("quantity", {
                    required: t(
                      "collectionOfferModal.form.quantityRequired.error",
                      "Quantity is required",
                    ),
                    min: {
                      value: 1,
                      message: t(
                        "collectionOfferModal.form.min.error",
                        "Please enter a valid quantity",
                      ),
                    },
                    max: {
                      value: maxQuantity.toNumber(),
                      message: t(
                        "collectionOfferModal.collectionOfferQuantity",
                        "Maximum of {{maximumCollectionOffers}} offers",
                        {
                          maximumCollectionOffers:
                            MAX_COLLECTION_OFFER_QUANTITY,
                        },
                        { forceString: true },
                      ),
                    },
                  })}
                  endEnhancer={
                    <UnstyledButton
                      disabled={bn(quantity).gte(maxQuantity)}
                      onClick={() => {
                        setValue("quantity", bn(quantity).plus(1).toString(), {
                          shouldValidate: true,
                        })
                      }}
                    >
                      <InteractiveIcon size={16} value="add" />
                    </UnstyledButton>
                  }
                  overrides={{
                    Input: { style: { textAlign: "center" } },
                  }}
                  startEnhancer={
                    <UnstyledButton
                      disabled={bn(quantity).lte(0)}
                      onClick={() => {
                        setValue("quantity", bn(quantity).minus(1).toString(), {
                          shouldValidate: true,
                        })
                      }}
                    >
                      <InteractiveIcon size={16} value="remove" />
                    </UnstyledButton>
                  }
                  type="number"
                  width={isMdScreen ? "136px" : "104px"}
                />
              </Item.Side>
            </Item>
          </Form.Control>

          {collection?.isTraitOffersEnabled ? (
            <Flex className="mb-6 border-b border-b-level-2 pb-6">
              {traitsPanelIsOpen ? (
                <LazyTraitSelector
                  collectionSlug={collection.slug}
                  overrides={{
                    Input: {
                      placeholder: t(
                        "collectionOfferModal.traitSelector.placeholder",
                        "Search traits",
                      ),
                      startEnhancer: (
                        <VerticalAligned className="mr-2">
                          <Icon
                            className="text-secondary"
                            size={24}
                            value="search"
                          />
                        </VerticalAligned>
                      ),
                      id: "offer-modal-trait-selector",
                    },
                    FormControl: {
                      className: "w-full",
                      label: traitsLabel,
                      htmlFor: "offer-modal-trait-selector",
                    },
                  }}
                  renderEmptyState
                  selectedTrait={selectedTrait}
                  showItemCount={false}
                  onSelectTrait={onSelectTrait}
                />
              ) : (
                traitsLabel
              )}
            </Flex>
          ) : null}

          <Form.Control
            className={collection?.isTraitOffersEnabled ? undefined : "!mt-4"}
            label={collection?.isTraitOffersEnabled ? durationLabel : undefined}
          >
            <>
              {!collection?.isTraitOffersEnabled && (
                <Label htmlFor="bidExpiration">{durationLabel}</Label>
              )}
              {durationPanelIsOpen ? (
                <Flex className="flex-col md:flex-row">
                  <Block
                    marginBottom={{ _: "8px", md: undefined }}
                    marginRight={{ _: undefined, md: "8px" }}
                    maxWidth={{ _: "100%", md: "150px" }}
                  >
                    <Controller
                      control={control}
                      name="bidExpiration"
                      render={({ field }) => {
                        return (
                          <Select
                            clearable={false}
                            options={collectionExpirationOptions}
                            overrides={{
                              Dropdown: {
                                props: {
                                  placement: "bottom",
                                  popperOptions: {
                                    modifiers: [
                                      {
                                        name: "flip",
                                        enabled: true,
                                      },
                                    ],
                                  },
                                },
                              },
                            }}
                            readOnly
                            value={bidExpirationValue}
                            onSelect={option => {
                              if (option) {
                                setBidExpirationLabel(option.label)
                                setBidExpirationValue(option.value)
                                field.onChange(
                                  option.date ?? defaultCustomExpiration,
                                )
                              }
                            }}
                          />
                        )
                      }}
                    />
                  </Block>
                  <Block flexGrow={1} minWidth={0}>
                    <DatePicker
                      max={maxOfferDuration}
                      min={defaultCustomExpiration}
                      overrides={{
                        Popover: {
                          placement: "bottom",
                          popperOptions: {
                            modifiers: [
                              {
                                name: "flip",
                                enabled: true,
                              },
                            ],
                          },
                        },
                        Button: {
                          style: {
                            fontWeight: 400,
                            width: "100%",
                            height: "48px",
                          },
                        },
                      }}
                      value={bidExpiration}
                      withTime
                      onChange={value => {
                        setBidExpirationLabel("Custom")
                        setBidExpirationValue("custom")
                        setValue("bidExpiration", value)
                      }}
                    />
                  </Block>
                </Flex>
              ) : (
                <></>
              )}
            </>
          </Form.Control>
          {collection && (
            <CollectionOfferDetails
              className={collection.isTraitOffersEnabled ? "pt-[28px]" : "pt-4"}
              collection={collection}
              quantity={Number(quantity)}
              renderPriceSide={renderPriceSide}
              trait={selectedTrait ?? undefined}
              variant="collection-offer-modal"
            />
          )}
        </Block>
      </Modal.Body>

      <FlexColumn className="w-full">
        <Modal.Footer padding="24px 24px 12px">
          <Modal.Footer.Button
            disabled={
              // Don't disable the button if the only error is the floor price warning
              (!formState.isValid && !hasFloorPriceWarning) ||
              bn(totalPrice ?? 0).isLessThanOrEqualTo(0)
            }
            type="submit"
          >
            {t("collectionOfferModal.submit", "Make offer")}
          </Modal.Footer.Button>

          {showAddFunds && symbol && (
            <Modal.Footer.Button
              variant="secondary"
              onClick={() => {
                onNext(
                  <AddFundsModalContent
                    chain={chain}
                    fundsToAdd={totalUsdPrice ?? undefined}
                    requiredAssetAmount={
                      totalPrice
                        ? bn(totalPrice).minus(selectedPaymentAssetBalance)
                        : undefined
                    }
                    symbol={symbol}
                    onFundsAdded={() =>
                      onReplace(
                        <CollectionOfferProvider
                          defaultOfferPrice={pricePerUnit}
                          defaultSelectedTrait={selectedTrait}
                        >
                          <CollectionOfferModal
                            account={accountDataKey}
                            collection={collectionDataKey}
                            tradeLimits={tradeLimitsDataKey}
                            onClose={onClose}
                          />
                        </CollectionOfferProvider>,
                      )
                    }
                  />,
                )
              }}
            >
              {symbol === "WETH"
                ? t("collectionOfferModal.addWETH", "Add WETH")
                : t("collectionOfferModal.addFunds", "Add funds")}
            </Modal.Footer.Button>
          )}
        </Modal.Footer>

        {showOfferProtectionAlert && (
          <Modal.Body paddingBottom="32px" paddingTop="0px">
            <Media lessThan="lg">
              <Flex className="items-center justify-center">
                <Icon {...offerProtectionInfoProps} size={16} />
                <Info size="tiny">{offerProtectionInfo}</Info>
              </Flex>
            </Media>
            <Media greaterThanOrEqual="lg">
              <Flex className="items-center justify-center">
                <Icon {...offerProtectionInfoProps} size={20} />
                <Info size="small">{offerProtectionInfo}</Info>
              </Flex>
            </Media>
          </Modal.Body>
        )}
      </FlexColumn>
    </Modal.Form>
  )
}

const Info = styled(Text.Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.text.secondary};
`

const InteractiveIcon = styled(Icon)`
  ${interactiveStylesPrimary};
`
